import './Services.css'
import { motion } from "framer-motion";
import { useState} from 'react';
import { AiOutlineExport } from "react-icons/ai";
import { Link, animateScroll as scroll } from 'react-scroll';
import shiatsuPic from './shiatsu-kep.png';

const colorCodes = ['#635147','#882d17','#cc7722','#a52a2a']
const variants = {
  open: { scale:3 ,rotate: 360},
  closed: { scale:1 },
}
export const HexagonAnimation = (props) =>{
  const styleVariant = {
    open: {zIndex:1000, marginLeft: props.sor * 30},
    closed:{zIndex:1,marginLeft: props.sor * 30}
  }
  const [isOpen, setIsOpen] = useState(false);
  //long text off
  let shownedText = isOpen ? props.text : props.text;
  if(props.longText == null){shownedText=props.text};
      return(
      <div className='hexagon' style={isOpen ? styleVariant.open : styleVariant.closed}>
      <motion.a className='hexagon-inner' 
      animate={isOpen ? "open" : "closed"}
      variants={variants}
      onClick={() => {setIsOpen(isOpen => !isOpen);
      }}
      initial={{ opacity: 0}}
      whileInView={{ opacity: 1,x: [0, 25, 0]}}
      viewport={{ once: true }}
      style={{backgroundColor:props.colorCode}}
      >
        <p className='hexaText'>{shownedText}</p>
        {isOpen ? <Link to={props.text} smooth={true} duration={1000} className='hexaClick' onClick={()=>{setIsOpen(false);}}><AiOutlineExport color='white'/>vigyél oda...</Link> : <></>}
        
      </motion.a>
    </div>
      )
}


function Services(){

      return(
        <div id="services" className='servicesContainer'>
          <h1 className='headerText'>Szolgáltatásaim</h1>
          <div id="services" className='grid'>
            <div className='line1'>
                <div className='hexagrid'>
                  <HexagonAnimation text="Shiatsu" colorCode={colorCodes[0]}/>
                  <HexagonAnimation text="Frissítő masszázs" colorCode={colorCodes[1]}/>
                  <HexagonAnimation text="HulumQi" colorCode={colorCodes[2]}/>
                  <HexagonAnimation text="Gyermekmasszázs" colorCode={colorCodes[3]}/>
                  <HexagonAnimation text="Vescerál" colorCode={colorCodes[0]}/>
                  <HexagonAnimation text="Nyirokmasszázs" colorCode={colorCodes[1]}/>

                </div>
            </div>
            <div className='line2'>
              <div className='hexagrid'>
                  <HexagonAnimation text="FDM" colorCode={colorCodes[2]}/>
                  <HexagonAnimation text="FMA" colorCode={colorCodes[3]}/>
                  <HexagonAnimation text="Neuro" colorCode={colorCodes[0]}/>
                  <HexagonAnimation text="Nervus Vagus" colorCode={colorCodes[1]}/>
                  <HexagonAnimation text="Hegkezelés" colorCode={colorCodes[2]}/>
                  <HexagonAnimation text="Niann" colorCode={colorCodes[3]}/>
              </div>
            </div>
            <div className='line3'>
              <div className='hexagrid'>
                  <HexagonAnimation text="Flossing" colorCode={colorCodes[0]}/>
                  <HexagonAnimation text="Kinesio"colorCode={colorCodes[1]}/>
                  <HexagonAnimation text="Fasciakés" colorCode={colorCodes[2]}/>
                  <HexagonAnimation text="Bemer" colorCode={colorCodes[3]}/>
                  <HexagonAnimation text="Moxa" colorCode={colorCodes[0]}/>
                  <HexagonAnimation text="OnBrain" colorCode={colorCodes[1]}/>
              </div>
            </div>
          </div>
          <div className="detailedServiceGrid">
            <div id="Niann" className='detailSection'style={{backgroundColor: colorCodes[2],marginBottom: '0'}}>
              <h1 className='detailTitle'>Niann terápiás program</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              1.5 órás testkezelés egy komplex terápiás ajánlással. 12 hét közösen felépített munka, lépésről lépésre követhető, napi rutinba könnyen beleilleszthető garantált eredményt hozó kezelés. Ha hormonjai őskáoszt okoznak, akkor ezt a módszert érdemes kipróbálni. Gondolkozzunk komplexen, hogy a változás és a jobb közérzet ne csak egy vágy legyen, hanem a mindennapok részeként éljük meg. Száraz bőr, hajhullás, hasra, combra hízás, gyulladt bőr oka mind lehet a nem megfelelően működő nyirokrendszer, és a hormonrendszer felborulása. A menopausa időszakát sokkal könnyedebbé tudja tenni a NIANN! Tudd meg, hogyan segíthet a Niann program a cellulit csökkentésében.</p>
              </div>
              <h1 className='detailTitle'>ÁR: 13.000,-Ft (1,5 óra) </h1>
           </div>
           <div id="Shiatsu" className='detailSection' style={{backgroundColor: colorCodes[0]}}>
              <h1 className='detailTitle'>Shiatsu kezelés</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Egy egészségmegőrző és regeneráló módszer, amely a keleti gyógyászat évezredes hagyományára támaszkodik. Merdiánok mentén az akupunktúrás pontok érintésével, ujjnyomással, illetve mély tenyérnyomással haladok végig az egész test felületén. Személyre szabott 1, illetve 1,5 órás pihetnető, testet-lelket lenyugtató kezelés. Felnőtteknek és gyermekeknek egyaránt minőségi feltöltődést biztosít ez a lágy, mélyen relaxáló technika. Sok ringatással, nyújtással, tekeréssel, kimozgatással javítjuk a meridiánokban az áramlást, ezzel biztosítva a csi és a vér áramlását az optimális állapot eléréséhez. 
              </p>
              {/*<img className='detailImg' src={shiatsuPic} style={{height:'10rem',width:'10rem'}}/>*/}
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft (1 óra)  13.000,-Ft (1,5 óra) </h1>
           </div>
           <div id="Frissítő masszázs" className='detailSection'style={{backgroundColor: colorCodes[1]}}>
              <h1 className='detailTitle'>Általános frissítő masszázs</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Törekszem a frissítő masszázst is személyre szabottá tenni. Aznap, ahogy megérkezik hozzám a vendég, ahhoz igazítom a nálam töltött 1 órás alkalmat. Ez a masszázs a keleti kezelésektől eltérően fehérneműben történik, de az éppen nem kezelt testfelület folyamatos takarásával biztosítom a komfortérzet megélését. A simítások,dörzsölések,gyúró és ütögető mozdulatok intenzív vérkeringést fokoznak, így elérve a kellemes ellazult közérzetet.  Jótékony hatását az igénybevett izmokon, izomgörcsökön, feszült szalagokon, zsibbadó végtagokon, beszűkült vállakon, csípőn, derékon, háton, nyakon fejti ki.              </p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft/óra</h1>
           </div>
           <div id="HulumQi" className='detailSection'style={{backgroundColor: colorCodes[2]}}>
              <h1 className='detailTitle'>Hulum Qi kezelés</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Ennen a kínai kezelésben 54 akupunktúrás pontot használunk, mely a QI energia kiegyenlítődését szolgálja. Feladatai közé tartozik, hogy a testből a nyákot kivezesse. Ennek érdekében a hason is vannak különböző mélységű fogások. Ez a  kezelés masszázságyon, ruhában történik. Dinamikus kezelés, ezzel elérve a test felfrissülését.</p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft (1 óra)  13.000,-Ft (1,5 óra) </h1>
           </div>
           <div id="Gyermekmasszázs" className='detailSection'style={{backgroundColor: colorCodes[3]}}>
              <h1 className='detailTitle'>Gyermek masszás kettőtől 12 éves korig</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              A szülők jelenlétében kezelem a gyereket, alapos kikérdezés és a fizikai állapot felmérése után földre vagy masszázságyra fektetem a gyermeket. A masszázs az emésztési nehézségben, a nehéz elalvásban és az éjszakai megébredés esetén tud segítségükre lenni. Nagyobb gyerekeknek iskolai stressz ellen, fájó izmok lazítására, növésből adódó fájdalmakra tud enyhülést adni. Sportoló gyerekek esetében a masszázson túl további eszközöket is segítségül hívok, ilyen például a köpöly, a flossing és a kinesio tape.               </p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft/óra</h1>
           </div>
           <div id="Vescerál" className='detailSection'style={{backgroundColor: colorCodes[0]}}>
              <h1 className='detailTitle'>Vescerális terápia</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Zsigeri, hasi szervek, belső szervek összefoglaló neve. Elsősorban a hason dolgozom, majd a hozzárendelt távolabbi testtájakat is bekapcsolom a folyamatba. Minden belső szervnek megvan a hozzárendelt külvilágra nyíló szerve - például a máj a szemhez tartozik, a fül a vesék állapotáról ad információt. Fájó váll esetén akár az epe nem megfelelő működése lehet az ok, térdfájdalom esetén gondolhatunk urológiai problémára -, ezért egy alapos kikérdezés, majd mozgástesztelés után megkezdődik a kezelés sorozat, amely minimum 5 kezelést jelent. Ehhez a kezeléshez nélkülözhetetlen az otthoni feladatok elvégzése, amit minden esetben közösen begyakorolunk. Panaszok, amelyeken segíthet: mélyen lévő, kisugárzó, görcsös állapot, puffadás, húzó-égő fájdalom, reflux, bélpanaszok, hasi műtétek és gyulladások után, endometriózis, gáttájékon, prosztata körül jelentkező fájdalom, valamint szervsüllyedés esetén.</p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft (1 óra)  13.000,-Ft (1,5 óra) </h1>
           </div>
           <div id="Nyirokmasszázs" className='detailSection'style={{backgroundColor: colorCodes[1]}}>
              <h1 className='detailTitle'>Nyirokmasszázs</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Egészségünk egyik fő alkotóeleme az immunrendszer védekezőképessége, ami csak a megfelelően működő nyirokáramlás esetén tud megvalósulni. Három fő feladatot lát el a nyirokrendszer a szervezetünkben: a fehérvérsejteket termel, ezzel védekezik a kórokozók ellen, ezzel erősítve az immunrendszert, a szövetközti térből visszajuttatja a folyadékot a vérkeringésbe, ezzel megakadályozva az ödéma keletkezését. A tápanyagok, zsírok, hormonok szállításával, a salakanyagok kiválasztásával segíti a vérkeringésen keresztül a méregtelenítő folyamatokat. Ez egy lassú, finom, söprögető simítással, nyomással történő kezelés. Hatásos ödéma esetén, műtétek utáni regenerálódásra, ficam esetén, gyulladási folyamatok lezajlása utáni szövetregenerálásra, szülés utáni felépülés felgyorsítására.</p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft/óra</h1>
           </div>
           <div id="FDM" className='detailSection'style={{backgroundColor: colorCodes[2]}}>
              <h1 className='detailTitle'>Fascia disztorziós modell FDM és baba FDM</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Jelenleg a leggyorsabban terjedő fájdalomcsillapító terápia. Mozgásszervi elváltozásokban, akut, illetve krónikus állapotokban egyaránt hatékony módszer. A panaszokat a testbeszéden keresztül is felmérem, majd az elmondottak alapján a hat lehetőségből kiválasztom az ideális kezelési módot. A test egy fantasztikus térkép és ha le tudjuk olvasni az üzenetet, hihetetlen gyorsasággal reagál rá. Ennél az alkalomnál mindig alkalmazok flossingot vagy fascia késes terápiás eszközöket. Esetenként a fájdalmat felerősítve jutunk el a várt eredményig, de kétperces fájdalommal felülírható az akár hónapok óta tartó fájdalom is. 
Babák esetén újszülött kortól kezdve használható technika, amely a gyermek kötőszöveteire, ízületeire, izmaira is jótékony hatással van. Székrekedési problémák, nyugtalanság, dongaláb, ferde fejtartás, egyoldali mozgás. nehézkes születés esetén ideális kezelés, gyors és hatékony.

                </p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft/óra</h1>
           </div>
           <div id="FMA" className='detailSection'style={{backgroundColor: colorCodes[3]}}>
              <h1 className='detailTitle'>Funkcionális mozgás analízis FMA</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Gyermekektől az idős embereik megbízható vizsgálati metódus. Három részen kereshető az ok: környezeti hatások, belső rendszer felborulása esetén, külvilágból beérkezett inger által létrehozott diszfunkciót keresünk. Az okból pedig okozattá válik egy-egy nem jól funkcionáló izom, ízület, testrész, de akár még a szemünkön keresztül bejövő információ is létrehozhat egy diszfunkciót. Ezekre keressük a válaszokat a tesztekkel. A kiértékelést követően meg is kezdjük a közös munkát. Ennél a módszernél is feltétel a házi feladat elvégzése a javulás érdekében. Az agyunk néha furcsa dolgokat tud létrehozni és mi ezeket tudjuk átírni akár egy mozgásminta újratanulásával, ezzel erősítve a pozitív változást.              </p>
              </div>
              <h1 className='detailTitle'>ÁR: 13.000,-Ft (1,5 óra)</h1>
           </div>
           <div id="Neuro" className='detailSection'style={{backgroundColor: colorCodes[0]}}>
              <h1 className='detailTitle'>Neuro mozgáskorrekció 1-es szint</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Ennél a technikánál a központi idegrendszert szólítjuk meg. Az agyunk a főnök, de ő sem tévedhetetlen. Így történhet meg, hogy rossz impulzust küld ki egy-egy területre. Például a tetoválás kis tűszúrásait az érzőidegeken keresztül egy folyamatos rossz ingernek foghatja fel és az izomban, a fasciában húzó, megrövidült, beszűkült mozgásállapotot képes létrehozni. Ugyanúgy a túlterhelt bélrendszer is képes hibás működést eredményezni, de ezt is ki lehet tesztelni tónusszabályzó mechanizmusokon keresztül. Régi sérülésből fennálló rossz mozgásminta felderítése és korrekciója azonnali visszateszteléssel akár perceken belüli javulást hozhat. Például egy állkapocs diszfunkció létrehozhat nyaki fájdalmat, erre is jó korrekció lehet ez a technika.              </p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft/óra</h1>
           </div>
           <div id="Nervus Vagus" className='detailSection'style={{backgroundColor: colorCodes[1]}}>
              <h1 className='detailTitle'>Nervus Vagus kezelés</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Magyar neve bolygóideg, mely a 10. agyidegből indulva bejárja az egész zsigeri részünket. Az agytörzsből kilépve kapcsolódik a kisagyhoz, onnan a koponyaalapon keresztül kapcsolódik a mellkashoz, a szívhez és a hasüregi szerveket egészen a vastagbélig kapcsolja össze. Ezen az információs sztrádán ellenőrizetlenül kapja az agyunk az impulzusokat. A szimpatikus és paraszimpatikus idegrendszert szólítja meg többezer impulzus a mai rohanó életmódból adódóan. Így általában a szimpatikus idegrendszer lett túlsúlyban használva, aminek a következménye az állandó ,,üss vagy fuss” hatás miatt nem tud kikapcsolni az agy. Ennek következménye lehet többek között az emelkedett pulzus és vérnyomás. A Vagus kezeléssel lehet megszólítani a paraszimpatikus idegrendszert, hogy nyugodjon vissza, így megsegítve a hormonrendszert, az emésztőrendszert és az érzelmi ingereket. Ha túl sok a teher rajtunk, de szeretnénk magunknak segíteni és kapcsolódni kicsit a bolygóideghez, akkor dudorásszunk, lélegezzünk lassan és mélyen, kopogtassuk a fülmögöti koponyarészünket pár percig, így elérve az ellazulás állapotát. Vegyünk részt egy pihentető 1 órás masszázson, aminek 20 percben része a Vagus kezelés.               </p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft/óra</h1>
           </div>
           <div id="Hegkezelés" className='detailSection'style={{backgroundColor: colorCodes[2]}}>
              <h1 className='detailTitle'>Hegkezelés</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              A hegkezelés a műtét vagy baleset utáni 12 héttel később lehet elkezdeni, amikor már a varratok biztonságosan összeforrtak. Ajánlatos a heget felszabadítani, mert sok kellemetlenségtőlóvhatjuk meg magunkat. Többféle típusa van a hegnek: hipertrófiás, keloidos és atrófiás. A hegek minél frissebbek, annál nagyobb esély van a kezelés sikerére, azonban a hegek mélységét, kiterjedését lehet csökkenteni. A hegek a mélyben összenövéseket, többirányú húzóerőt hozhatnak létre, így alakulnak ki a letapadások. Akár 30 éves hegekkel is lehet dolgozni és pár kezelés alkalmával megkönnyebbülést hozhat az életminőség javulásával.               </p>
              </div>
              <h1 className='detailTitle'>Terápia részeként alkalmazom</h1>
           </div>
           <div id="Flossing" className='detailSection'style={{backgroundColor: colorCodes[3]}}>
              <h1 className='detailTitle'>Flossing terápia </h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Gyors és hatékony módszer a kisebb sportsérülésektől kezdve a nagyobb fájdalmakig. Hat a fasciára, az izomra és a szalagokra. Különböző színű gumiszalagok alkalmazásával kompressziót hozok létre az adott területen. Fájdalomcsillapítás, beszűkült mozgástartomány oldására hasznos rehabilitációs eszköz. Felhelyezése után az adott terület átmozgatásával segíti a szövetek regenerálódását, az ödéma csökkenését. Eredményesen alkalmazható például alagútszindróma, ínhüvelygyulladás, ödémásodás, részleges szalagszakadás és bokaficam esetén.               </p>
              </div>
              <h1 className='detailTitle'>Terápia részeként alkalmazom</h1>
           </div>
           <div id="Kinesio" className='detailSection'style={{backgroundColor: colorCodes[0]}}>
              <h1 className='detailTitle'>Kinesio tape </h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Egy igazi kincs hozzáértő kézben. Gyors segítség a fájdalomcsillapítás terén, az izmok stabilizálásában vagy nyújtásában, duzzanatok, véraláfutások mihamarabbi csökkenésében. Nem tartalmaz semmilyen fájdalomcsillapító szert, a felhelyezés technikájával tudjuk befolyásolni a kívánt hatást. A felragasztás után a szöveteket elhúzza egymástól, így biztosítva a nagyobb nyirok- és véráramlást az adott területen. Hideg, meleg és semleges színben érhetőek el. Plusz kiegészítésként szoktam használni cross tape-et is, ennek a működési elve, hogy trigger vagy akupunktúrás pontra felhelyezve egy folyamatos ingert ad az agynak, így hozzájárul a gyorsabb regenerálódáshoz.              </p>
              </div>
              <h1 className='detailTitle'>Terápia részeként alkalmazom</h1>
           </div>
           <div id="Fasciakés" className='detailSection'style={{backgroundColor: colorCodes[1]}}>
              <h1 className='detailTitle'>Fasciakéses kiegészítő terápia  </h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              A fascia az emberi testet behálózó sűrű kollagén rost, aminek az első rétege a bőr alatt 2 mm mélyen található. A teljes testüket, izmainkat, belső szerveinket behálózza, így adva ezeknek tartást, mozgékonyságot, rugalmasságot és védelmet. A fascia érdekes módon tárolja a régi sérüléseket, így okozva néha megmagyarázhatalan fájdalmakat, csavarodásokat. Fascialáncban gondolkozva igen hatásos eszköz a fasciakés a jelenlegi terápiás munkában. A neve ellenére nem egy éles eszköz, hanem egy speciálisan kialakított, fémből készült segédeszköz, amely kiválóan alkalmas a blokkok és szövetek oldására, így újra egészséges szövetté téve az adott területet.              </p>
              </div>
              <h1 className='detailTitle'>Terápia részeként alkalmazom</h1>
           </div>
           <div id="Köpölyözés" className='detailSection'style={{backgroundColor: colorCodes[2]}}>
              <h1 className='detailTitle'>Köpöly</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Műanyag vagy üveg harangok, amit hideg vagy meleg köpölyözésheze használok. A bőrre felhelyezve vákumot szívok az adott területen, ahol vérbőséget okoz. Pár perc elteltével a köpölyt leveszem és a felgyűlt vér és nyirok szétáradásának következtében a pangást vagy feszülést oldani képes. A bőr színéből következtetni lehet az adott terület állapotára. A test az öngyógyító folyamatát beindítja, a hiány vagy többletállapotot rendezve regenerálódik még egy pár nap erejéig. Ezért nem kell megijedni, ha a kezelés után 2-3 napig jobban fáj a kezelt terület és az elszíneződés meglátszódik.               </p>
              </div>
              <h1 className='detailTitle'>Terápia részeként alkalmazom</h1>
           </div>
           <div id="Moxa" className='detailSection'style={{backgroundColor: colorCodes[3]}}>
              <h1 className='detailTitle'>Moxázás</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              Gyógynövényekből összepréselt moxarudat használok. Ősi, keleti módszer, ami a testen lévő akupunktúrás pontokat vagy meridiánokat tölti fel a felhevített rúd segítségével. Pontban vagy csíkban a bőrtől picit távolabb kezelek, így létrehozva az egyensúlyi állapotot testben, lélekben. Az ötelem tana szerint a szervpárokban létrejövő elakadást, nedvességet, hideget vagy éppen a testben megjelenő tüzet ki lehet űzni, így törekedve a yin és yang összehangolódására. Többek között menstruációs fájdalmakra, hideg végtagokra, görcsös izmokra, emésztőrendszeri problémákra, derékfájásra, fejfájásra és veseproblémákra ajánlott.               </p>
              </div>
              <h1 className='detailTitle'>Terápia részeként alkalmazom</h1>
           </div>
           <div id="OnBrain" className='detailSection'style={{backgroundColor: colorCodes[0]}}>
              <h1 className='detailTitle'>On Brain 1-2-3-4 szint</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              fontosnak tartom a test kezeléséhez kapcsolódva a lélek megsegítését is, így ismerkedtem meg 2011-ben a kineziológiával. Előfordul, hogy egy makacs izom semmilyen terápiára nem reagál, ekkor nagyszerű lehetőség a továbblépésre egy-egy oldás. Izomteszt segítségével megkeressük az agymban eltárolt üzenetet, akár pozitív, akár negatív élmény kapcsolódik hozzá és a tudatalattiból előhívjuk a lelkiállapothoz tartozó blokkokat. Félelmek, szorongás, önbizalomhiány, stressz, fejfájás, gyomorfájás és még sok minden egyéb nehézséget lehetséges a megértés mezejére tenni a kineziológia segítségével.                </p>
              </div>
              <h1 className='detailTitle'>ÁR: 11.000,-Ft (1 óra)  13.000,-Ft (1,5 óra) </h1>
           </div>
           <div id="Bemer" className='detailSection'style={{backgroundColor: colorCodes[1],marginBottom: '0'}}>
              <h1 className='detailTitle'>Bemer kezelés, bérlési lehetőség, gépvásárlás</h1>
              <div className='detailTextContainer'>
              <p className='detailText'>
              25 éve fejlődő mágneses érterápiás orvostechnikai eszköz. Az erek rugalmasságát újra visszaadva napi minimum 2x8 perces kezelés szükséges. A mikrokeringés rendezésével 29%-ban megnöveli az oxigénellátását a testnek, 74%-ban javítja a keringést. Ennek köszönhetően a vitaminok, tápanyagok vagy adott esetben a gyógyszerek nagyobb részben tudnak felszívódni. A mikroereket is képes átjárhatóvá tenni, így nagyban hozzájárul a méregtelenítéshez és az immunrendszer erősítéséhez. Hazánkban jelenleg 17 kórházban használják gyermek és felnőtt osztályokon egyaránt. Ajánlott használni sebgyógyításra, strok utáni felépülésben, gyulladásos folyamatok legyőzésére, meddőség, depresszió, sclerosis multiplex, neuropátia, ADHD, enyhe autizmus, trombózis, csonttörés vagy ritkulás esetén, cukorbetegség következtében kialakult szövődmények esetén és számos egyéb problémánál érdemes igénybe venni a Bemer EVO segítségét. Személyre szabott programbeállítással egy bérlési időintervallumban több családtag is tudja használni otthona kényelmében. Ellenjavallat pacemaker, inzulinpumpa, szervátültetés, epilepszia, nem beszámítható tudatállapot, tisztázatlan eredetű láz fennállása esetén.
              </p>
              </div>
              <h1 className='detailTitle'>Aktuális árakról érdeklődjön telefonon, illetve látogasson el a következő <a href='https://www.facebook.com/Bemerposnyakagi'>linkre</a></h1>
           </div>
          </div>
        </div>
        
          );
}

export default Services;